import type { HTTPErrorType } from 'Errors/HTTPErrorType';

import type { QueryArg } from './types';

export const getMethod = (arg: QueryArg) => {
  const fallback = 'GET';

  if (typeof arg === 'string') return fallback;

  return arg?.method ?? fallback;
};

export const getRelativeUrl = (arg: QueryArg) => {
  if (typeof arg === 'string') return arg;

  return arg.url;
};

export const isAuthErrorMessage = (errorString: string) =>
  ['Cannot refresh token, must signin', 'Unauthenticated'].includes(errorString);

export const serializeError = (
  err: HTTPErrorType | Error,
  fallback = 'Unknown error from fetchJson base query'
) => {
  return (err as HTTPErrorType)?.body?.detail ?? err?.message ?? err?.toString() ?? fallback;
};
