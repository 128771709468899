import { isError } from 'types/predicates';

import createApiClient from 'services/CreateApiClient';

import logSentryError from 'utils/sentry';

import { savePagination } from 'dux/pagination/slice';

import type { FetchJson } from './types';
import { getMethod, getRelativeUrl, isAuthErrorMessage, serializeError } from './utils';

const fetchJson: FetchJson =
  ({ baseUrl }) =>
  async (args, api) => {
    const callApi = createApiClient(api.dispatch, getMethod(args));

    try {
      const response = await callApi(`${baseUrl}${getRelativeUrl(args)}`);
      const pages = response?.pages;

      if (pages) {
        api.dispatch(
          savePagination({
            pages,
            callType: api.type,
            endpoint: api.endpoint,
            arg: getRelativeUrl(args),
          })
        );
      }

      const payload = await response.json();
      return { data: payload };
    } catch (error) {
      if (!isError(error)) return { error: null }; // it's always an error so shouldn't get here

      const errorString = serializeError(error);

      if (!isAuthErrorMessage(errorString)) {
        logSentryError(`[fetchJson base query] ${api.endpoint}`, error); // no need to log to Sentry if it's an auth error
      }

      return { error: errorString };
    }
  };

export default fetchJson;
