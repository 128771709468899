import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { BaseQueryApi } from '@reduxjs/toolkit/query';

import get from 'lodash/fp/get';

import type { RootState } from 'store';
import type { OmsSelector, Pages } from 'types/common';

type Params = {
  arg: string;
  callType: BaseQueryApi['type'];
  endpoint: BaseQueryApi['endpoint'];
};

const paginationSlice = createSlice({
  name: 'pagination',
  initialState: { query: {}, mutation: {} },
  reducers: {
    savePagination(draftState, action: PayloadAction<{ pages: Pages } & Params>) {
      const { callType, endpoint, arg, pages } = action.payload;
      draftState[callType][`${endpoint}("${arg}")`] = pages;
    },
  },
});

const getPaginationState: OmsSelector<RootState['pagination']> = state => state.pagination;

export const getPagination: OmsSelector<Pages> = createSelector(
  getPaginationState,
  (_, { callType = 'query', endpoint, arg }: Params) => `${callType}.${endpoint}("${arg}")`,
  (paginationState, key) => get(key, paginationState) ?? {}
);

const { reducer, actions } = paginationSlice;

export const { savePagination } = actions;

export default reducer;
